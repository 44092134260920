var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"co-modals"},[_c('b-modal',{staticClass:"um__success--modal",attrs:{"id":_vm.USER_LAYOUT_MODAL_IDS.UNENROLL_SUCCESS_MODAL,"centered":"","size":"xs","no-close-on-esc":true,"no-close-on-backdrop":true},on:{"hide":function($event){return _vm.$emit(
        'unenrollSuccessModalClose',
        _vm.USER_LAYOUT_MODAL_IDS.UNENROLL_SUCCESS_MODAL
      )}},scopedSlots:_vm._u([{key:"modal-header",fn:function({ hide}){return [_c('div',{staticClass:"d-flex justify-content-end w-100"},[_c('div',{staticClass:"close-icon-styles",on:{"click":function($event){return hide()}}},[_c('img',{attrs:{"src":require("@/assets/images/close.svg")}})])])]}},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 mb-3"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('Button',{staticClass:"cm__discovering--button",attrs:{"variant":"custom-primary-vle"},on:{"click":function($event){return _vm.$emit(
                'unenrollSuccessModalClose',
                _vm.USER_LAYOUT_MODAL_IDS.UNENROLL_SUCCESS_MODAL
              )}}},[_vm._v(" "+_vm._s(_vm.$t("course.unenroll.unenrolled_alert.keep_discovering_button"))+" ")])],1)])]},proxy:true}])},[_c('div',{staticClass:"text-center um__success--modal"},[_c('img',{staticClass:"cm__check--image",attrs:{"src":require("@/assets/images/vle/checkmark.svg")}})]),_c('div',{staticClass:"cm__body--content text-center um__success--modal"},[_c('div',{staticClass:"cm__unenroll--alert"},[_vm._v(" "+_vm._s(_vm.$t("course.unenroll.unenrolled_alert.title"))+" ")]),_c('div',{staticClass:"cd-sec__des cm__unenroll--message"},[_vm._v(" "+_vm._s(_vm.$t("course.unenroll.unenrolled_alert.message"))+" ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }