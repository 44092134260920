<template>
  <div class="co-modals">
    <!--Unenroll success modal-->
    <b-modal
      :id="USER_LAYOUT_MODAL_IDS.UNENROLL_SUCCESS_MODAL"
      @hide="
        $emit(
          'unenrollSuccessModalClose',
          USER_LAYOUT_MODAL_IDS.UNENROLL_SUCCESS_MODAL
        )
      "
      centered
      size="xs"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      class="um__success--modal"
    >
      <template #modal-header="{ hide}">
        <div class="d-flex justify-content-end w-100">
          <div class="close-icon-styles" @click="hide()">
            <img src="@/assets/images/close.svg" />
          </div>
        </div>
      </template>
      <div class="text-center um__success--modal">
        <img src="@/assets/images/vle/checkmark.svg" class="cm__check--image" />
      </div>
      <div class="cm__body--content text-center um__success--modal">
        <div class="cm__unenroll--alert">
          {{ $t("course.unenroll.unenrolled_alert.title") }}
        </div>
        <div class="cd-sec__des cm__unenroll--message">
          {{ $t("course.unenroll.unenrolled_alert.message") }}
        </div>
      </div>

      <template #modal-footer>
        <div class="w-100 mb-3">
          <div class="d-flex justify-content-center">
            <Button
              variant="custom-primary-vle"
              @click="
                $emit(
                  'unenrollSuccessModalClose',
                  USER_LAYOUT_MODAL_IDS.UNENROLL_SUCCESS_MODAL
                )
              "
              class="cm__discovering--button"
            >
              {{
                $t("course.unenroll.unenrolled_alert.keep_discovering_button")
              }}
            </Button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Button from "@/components/WrapperComponents/Button.vue";
import USER_LAYOUT_MODAL_IDS from "./constants";
import { mapGetters } from "vuex";
export default {
  components: { Button },
  props: {
    courseId: {
      type: String
    }
  },
  data() {
    return {
      USER_LAYOUT_MODAL_IDS: {}
    };
  },
  computed: {
    ...mapGetters(["allConfig"])
  },
  mounted() {
    this.USER_LAYOUT_MODAL_IDS = USER_LAYOUT_MODAL_IDS;
  },
  methods: {
    closeModal(id) {
      this.$bvModal.hide(id);
    }
  }
};
</script>
